<template>
  <Portlet
    :title="`Audit Logs (${ auditLogsCount })`"
    icon="clipboard-list"
    class="audit-logs mt-4"
  >
    <template slot="buttons">
      <font-awesome-icon
        class="mr-1"
        :icon="isAccordionOpen ? 'minus' : 'plus'"
        style="cursor: pointer;"
        data-toggle="collapse"
        href=".databaseAuditLogsList" 
        @click="changeAccordionState"
      />
      <div class="clearfix" />
    </template>
    <div :class="['databaseAuditLogsList', {'collapse': collapsed}] ">
      <LoadingPlaceholder v-if="loading" />
      <template v-else>
        <template v-if="audits">
          <Grid
            :ref="kgm_ref"
            :style="{height: 'auto'}"
            :data-items="kgm_computedGridItems(audits)"
            :columns="kgm_responsiveColumns()"
            :filterable="true"
            :filter="kgm_filter"
            :pageable="kgm_pagable"
            :page-size="kgm_take"
            :skip="kgm_skip"
            :take="kgm_take"
            :total="kgm_allGridItems(audits)"
            :sortable="{
              allowUnsort: kgm_allowUnsort,
              mode: kgm_sortMode
            }"
            :sort="kgm_sort"
            selected-field="selected"
            @rowclick="kgm_gridOnRowClick"
            @filterchange="kgm_gridFilterChange"
            @pagechange="kgm_gridPageChange"
            @sortchange="kgm_gridSortChange"
          >
            <template
              slot="installationUrl"
              slot-scope="{props}"
            >
              <td :class="props.className">
                <router-link
                  v-if="props.dataItem.entity == 'TerminalVnc'"
                  :to="`/installation/${ props.dataItem.installationId }/devices/device/${props.dataItem.entityId}`"
                  class="alt-primary-color"
                >
                  {{ kgm_getNestedValue(props.field, props.dataItem) }}
                </router-link>
                <router-link
                  v-else
                  :to="`/installation/${ props.dataItem.installationId }/software-components/${props.dataItem.parentId}`"
                  class="alt-primary-color"
                >
                  {{ kgm_getNestedValue(props.field, props.dataItem) }}
                </router-link>
              </td>
            </template>
            <div
              slot="idFilter"
              slot-scope="{props, methods}"
              class="input-group"
            >
              <input
                ref="idFilterInput"
                :value="props.value"
                type="text"
                class="form-control mr-2 rounded-right"
                placeholder="Filter by Id"
                @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
              >
              <div
                v-if="props.value"
                class="input-group-append"
              >
                <button
                  class="btn btn-light border rounded"
                  @click="kgm_resetInput('idFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
                >
                  <font-awesome-icon
                    class="gray"
                    icon="times"
                  />
                </button>
              </div>
            </div>
            <div
              slot="logTypeFilter"
              slot-scope="{props, methods}"
              class="input-group"
            >
              <input
                ref="logTypeFilterInput"
                :value="props.value"
                type="text"
                class="form-control mr-2 rounded-right"
                placeholder="Filter by Type"
                @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
              >
              <div
                v-if="props.value"
                class="input-group-append"
              >
                <button
                  class="btn btn-light border rounded"
                  @click="kgm_resetInput('logTypeFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
                >
                  <font-awesome-icon
                    class="gray"
                    icon="times"
                  />
                </button>
              </div>
            </div>
            <div
              slot="logDateFilter"
              slot-scope="{props, methods}"
              class="input-group"
            >
              <input
                ref="logDateFilterInput"
                :value="props.value"
                type="date"
                placeholder="Filter by Date"
                class="form-control mr-2 rounded-right"
                @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
              >
              <div
                v-if="props.value"
                class="input-group-append"
              >
                <button
                  class="btn btn-light border rounded"
                  @click="kgm_resetInput('logDateFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
                >
                  <font-awesome-icon
                    class="gray"
                    icon="times"
                  />
                </button>
              </div>
            </div>
            <div
              slot="systemFilter"
              slot-scope="{props, methods}"
              class="input-group"
            >
              <input
                ref="systemFilterInput"
                :value="props.value"
                type="text"
                class="form-control mr-2 rounded-right"
                placeholder="Filter by System"
                @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
              >
              <div
                v-if="props.value"
                class="input-group-append"
              >
                <button
                  class="btn btn-light border rounded"
                  @click="kgm_resetInput('systemFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
                >
                  <font-awesome-icon
                    class="gray"
                    icon="times"
                  />
                </button>
              </div>
            </div>
            <div
              slot="entityFilter"
              slot-scope="{props, methods}"
              class="input-group"
            >
              <input
                ref="entityFilterInput"
                :value="props.value"
                type="text"
                class="form-control mr-2 rounded-right"
                placeholder="Filter by Entity"
                @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
              >
              <div
                v-if="props.value"
                class="input-group-append"
              >
                <button
                  class="btn btn-light border rounded"
                  @click="kgm_resetInput('entityFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
                >
                  <font-awesome-icon
                    class="gray"
                    icon="times"
                  />
                </button>
              </div>
            </div>
            <div
              slot="entityIdFilter"
              slot-scope="{props, methods}"
              class="input-group"
            >
              <input
                ref="entityIdFilterInput"
                :value="props.value"
                type="text"
                class="form-control mr-2 rounded-right"
                placeholder="Filter by Entity Id"
                @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
              >
              <div
                v-if="props.value"
                class="input-group-append"
              >
                <button
                  class="btn btn-light border rounded"
                  @click="kgm_resetInput('entityIdFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
                >
                  <font-awesome-icon
                    class="gray"
                    icon="times"
                  />
                </button>
              </div>
            </div>
            <div
              slot="usernameFilter"
              slot-scope="{props, methods}"
              class="input-group"
            >
              <input
                ref="usernameFilterInput"
                :value="props.value"
                type="text"
                class="form-control mr-2 rounded-right"
                placeholder="Filter by Username"
                @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
              >
              <div
                v-if="props.value"
                class="input-group-append"
              >
                <button
                  class="btn btn-light border rounded"
                  @click="kgm_resetInput('usernameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
                >
                  <font-awesome-icon
                    class="gray"
                    icon="times"
                  />
                </button>
              </div>
            </div>
            <template
              slot="optionsTemplate"
              slot-scope="{props}"
            >
              <td :class="props.className">
                <button
                  class="btn btn-primary btn-sm"
                  @click="openChangesModal(props.dataItem)"
                >
                  <font-awesome-icon
                    class="mr-2"
                    icon="edit"
                  />
                  <span>Changes</span>
                </button>
              </td>
            </template>
            <template
              slot="dateTemplate"
              slot-scope="{props}"
            >
              <td>
                <span v-tooltip="dateTime_fromNow(kgm_getNestedValue(props.field, props.dataItem))">{{ dateTime_dateTime(kgm_getNestedValue(props.field, props.dataItem)) }}</span>
              </td>
            </template>
          </Grid>
        </template>

        <SweetModal
          ref="auditChanges"
          :title="'Rollback Changes'"
          class="overflowHidden"
          @close="resetSelectedAuditLog"
        >
          <template
            v-if="selectedAuditLog"
          >
            <ConfigurationCompare
              :current-config="oldConf"
              :previous-config="newConf"
            />
          </template>
          <button
            slot="button"
            class="btn btn-secondary float-left mb-3"
            @click="$refs.auditChanges.close()"
          >
            <font-awesome-icon
              class="mr-2"
              icon="times"
            />{{ $t('cancel') }}
          </button>
          <button
            v-if="!isInstallationChangelog && selectedAuditLog != null && selectedAuditLog.logType != 'View' && selectedAuditLog.logType != 'Login' && selectedAuditLog.logType != 'SessionDelete'"
            slot="button"
            class="btn btn-danger float-right mb-3"
            @click="rollbackObject()"
          >
            Rollback
          </button>
          <div class="clearfix" />
        </SweetModal>
      </template>
    </div>
  </Portlet>
</template>

<script>

import { SweetModal } from 'sweet-modal-vue';
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';

export default {
  name: "DatabaseAuditLogsList",
  components: {
    SweetModal,
    ConfigurationCompare: () => import('@/components/Config/ConfigurationCompare.vue'),
  },
  mixins: [
    kendoGridMixin,
    dateTimeMixin
  ],
  props: {
    collapsed: {
      type: Boolean,
      required: false,
      default () {
        return true;
      }
    },
    resortId: { //for ConverterConfigs
      type: Number,
      required: false,
      default () {
        return null;
      }
    },
    resortCrudId: { //for Resort Crud
      type: Number,
      required: false,
      default () {
        return null;
      }
    },
    isSmsTemplates: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    templateIdForMessage: {
      type: String,
      required: false,
      default () {
        return null;
      }
    },
    isCutConfigs: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    isInstallationConfig: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    isThemes: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    isThemeElements: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    themeId: {
      type: String,
      required: false,
      default () {
        return null;
      }
    },
    isIntroOutroElement: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    introOutroId: {
      type: String,
      required: false,
      default () {
        return null;
      }
    },
    isSounds: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    isFonts: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    isCollections: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    collectionId: {
      type: String,
      required: false,
      default () {
        return null;
      }
    },
    isApplicationSetting: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    isAckMessages: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    isSpots: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    isPhotopoint: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    isLift: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    isSharedResort: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    parentResortId: {
      type: Number,
      required: false,
      default () {
        return -1;
      }
    },
    isRoutingRules: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    isTracing: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    isTracingStatistic: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    isSoftwareComponentType: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    isSoftwareComponent: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    installationId: {
      type: String,
      required: false,
      default () {
        return null;
      }
    },
    isSoftwareComponentCommand: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    softwareComponentCommandParent: {
      type: String,
      required: false,
      default () {
        return '';
      }
    },
    isSoftwareComponentLogin: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    isInstallationLogin: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    isPeakLogin: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    componentIds: {
      type: Array,
      required: false,
      default () {
        return null;
      }
    },
    softwareComponentParentId: {
      type: String,
      required: false,
      default () {
        return '';
      }
    },
    isInstallationEvents: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    isBulkCommands: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    isQuickCommands: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    isInstallationLinks: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    isQuestionTemplates: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
     isOnCallDuty: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    templateIdForQuestion: {
      type: String,
      required: false,
      default () {
        return null;
      }
    },
    isSessionDelete: {
      type: Boolean,
      required: false,
      default () {
        return false;
      },
    },
    isAllLoginAudit: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    isInstallationChangelog: {
      type: Boolean,
      required: false,
      default () {
        return false;
      },
    },
    isContactRoles: {
      type: Boolean,
      required: false,
      default () {
        return false;
      },
    },
    isInstallationIp: {
      type: Boolean,
      required: false,
      default () {
        return false;
      },
    },
    isCustomers: {
      type: Boolean,
      required: false,
      default () {
        return false;
      },
    },
    isContact: {
      type: Boolean,
      required: false,
      default () {
        return false;
      },
    },
    from: {
      type: String,
      required: false,
      default () {
        return null;
      },
    },
    to: {
      type: String,
      required: false,
      default () {
        return null;
      },
    }
  },
  data () {
    return {
      isAccordionOpen: !this.collapsed,
      loading: true,
      selectedAuditLog: null,
      audits: null,
      kgm_sort: [
        {
          "field": "logDate",
          "dir": "desc"
        }
      ],
      kgm_columns: [
        {
          field: 'id',
          filterable: true,
          filter: 'text',
          title: 'Id',
          filterCell: 'idFilter',
          hideOn: ['mdDown'],
        },
        {
          field: 'logType',
          filterable: true,
          filter: 'text',
          title: 'Type',
          filterCell: 'logTypeFilter',
        },
        {
          field: 'logDate',
          filterable: true,
          filter: 'date',
          title: 'Date',
          cell: 'dateTemplate',
          filterCell: 'logDateFilter',
          hideOn: ['mdDown'],
        },
        {
          field: 'system',
          filterable: true,
          filter: 'text',
          title: 'System',
          filterCell: 'systemFilter',
          hideOn: ['mdDown'],
        },
        {
          field: 'entity',
          filterable: true,
          filter: 'text',
          title: 'Entity',
          filterCell: 'entityFilter',
        },
        {
          field: 'entityId',
          filterable: true,
          filter: 'text',
          title: 'Entity Id',
          filterCell: 'entityIdFilter',
          hideOn: ['mdDown'],
        },
        {
          field: 'username',
          filterable: true,
          filter: 'text',
          title: 'Username',
          filterCell: 'usernameFilter',
          hideOn: ['mdDown'],
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'optionsTemplate'
        }
      ]
    }
  },
  computed: {
    newConf () {
      return this.selectedAuditLog && this.selectedAuditLog.newValue ? this.selectedAuditLog.newValue : "{}";
    },
    oldConf () {
      return this.selectedAuditLog && this.selectedAuditLog.oldValue ? this.selectedAuditLog.oldValue : "{}";
    },
    auditLogsCount () {
      return this.audits != null ? this.audits.length : 0;
    }
  },
  watch: {
    resortId () {
      this.loadAudits();
    }
  },
  created () {
    if(!this.isAllLoginAudit) {
      this.loadAudits();
    }
    else {
      this.kgm_columns.splice(1, 0, {
          field: 'installationId',
          filterable: true,
          filter: 'text',
          title: 'InstallationId',
          filterCell: 'idFilter',
          hideOn: ['xlDown'],
          cell: 'installationUrl'
        })
    }
  },
  methods: {
    changeAccordionState () {
      this.isAccordionOpen = !this.isAccordionOpen;
    },
    resetSelectedAuditLog () {
      this.selectedAuditLog = null;
    },
    loadAudits () {
      this.loading = true;
      this.getAuditLogs();
    },
    reloadAuditLogs () {
      this.loading = true;
      this.getAuditLogs();
    },
    openChangesModal (auditLog) {
      this.selectedAuditLog = auditLog;
      this.$refs.auditChanges.open();
    },
    searchLoginAudits () {
      let url = `/DatabaseAuditLog/GetAllLoginAudits?from=${this.from}&to=${this.to}`;
      this.axios.get(url)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.audits = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getAuditLogs () {
      let url = "";

      if(this.isThemes) {
        url = "/DatabaseAuditLog/GetDatabaseByEntityName?entity=Theme&system=OnSite";
      }
      else if(this.isInstallationConfig) {
        url = "/DatabaseAuditLog/GetDatabaseByEntityName?entity=InstallationConfig&system=OnSite";
      }
      else if(this.isCutConfigs) {
        url = "/DatabaseAuditLog/GetDatabaseByEntityName?entity=CutConfig&system=OnSite";
      }
      else if(this.templateIdForMessage) {
        url = '/DatabaseAuditLog/GetDatabaseAuditLogsByParentId?parentId='+ this.templateIdForMessage +'&entity=SmsMessage&system=OnSite';
      }
      else if(this.isSmsTemplates) {
        url = "/DatabaseAuditLog/GetDatabaseByEntityName?entity=SmsTemplate&system=OnSite";
      } 
      else if(this.isQuestionTemplates) {
        url = "/DatabaseAuditLog/GetDatabaseByEntityName?entity=InstallationQuestionTemplate&system=OnSite";
      }
      else if(this.templateIdForQuestion) {
        url = '/DatabaseAuditLog/GetDatabaseAuditLogsByParentId?parentId='+ this.templateIdForQuestion +'&entity=InstallationQuestion&system=OnSite';
      } 
      else if(this.resortId) {
        url = "/DatabaseAuditLog/GetDatabaseAuditLogsByResortId?resortId="+this.resortId;
      }
      else if(this.resortCrudId) {
        url = "/DatabaseAuditLog/GetDatabaseAuditLogsForResort?resortId="+this.resortCrudId;
      }
      else if(this.isThemeElements) {
        url = "/DatabaseAuditLog/GetDatabaseAuditLogsByParentIdMultipleEntities?parentId="+ this.themeId + 
        "&entities=LayoutImageElement,LayoutTextElement,UserDependentImageElement,UserDependentTextElement,SoundElement2Theme&system=OnSite";
      }
      else if(this.isIntroOutroElement) {
        url = "/DatabaseAuditLog/GetDatabaseByEntityName?entity=IntroOutro&system=OnSite";
      }
      else if(this.introOutroId) {
        url = "/DatabaseAuditLog/GetDatabaseAuditLogsByParentIdMultipleEntities?parentId="+ this.introOutroId + 
        "&entities=IntroScene,OutroScene&system=OnSite";
      } 
      else if(this.isSounds) {
        url = "/DatabaseAuditLog/GetDatabaseByEntityName?entity=SoundElement&system=OnSite";
      }
      else if(this.isFonts) {
        url = "/DatabaseAuditLog/GetDatabaseByEntityName?entity=FontElement&system=OnSite";
      }
      else if(this.isCollections) {
        url = "/DatabaseAuditLog/GetDatabaseByEntityName?entity=ImageCollection&system=OnSite";
      }
      else if(this.collectionId) {
        url = '/DatabaseAuditLog/GetDatabaseAuditLogsByParentId?parentId='+ this.collectionId +'&entity=ImageCollectionElement&system=OnSite';
      }
      else if(this.isApplicationSetting) {
        url = "/DatabaseAuditLog/GetDatabaseByEntityName?entity=ApplicationSetting&system=OnSite";
      }
      else if(this.isAckMessages) {
        url = "/DatabaseAuditLog/GetDatabaseByEntityName?entity=AcknowledgeMessage&system=OnSite";
      }
      else if(this.isSpots) {
        url = "/DatabaseAuditLog/GetDatabaseAuditLogsByParentId?entity=Spot&system=Skiline&parentId=" + this.parentResortId;
      }
      else if(this.isPhotopoint) {
        url = "/DatabaseAuditLog/GetDatabaseAuditLogsByParentId?entity=Photopoint&system=Skiline&parentId=" + this.parentResortId;
      }
      else if(this.isLift) {
        url = "/DatabaseAuditLog/GetDatabaseAuditLogsByParentId?entity=Section&system=Skiline&parentId=" + this.parentResortId;
      }
      else if(this.isSharedResort) {
        url = "/DatabaseAuditLog/GetDatabaseAuditLogsByParentId?entity=ResortSharing&system=Skiline&parentId=" + this.parentResortId;
      }
      else if(this.isRoutingRules) {
        url = "/DatabaseAuditLog/GetDatabaseAuditLogsByParentId?entity=RoutingRules&system=Skiline&parentId=" + this.parentResortId;
      }
      else if(this.isTracing) {
        url = "/DatabaseAuditLog/GetDatabaseByEntityName?entity=ContactTracing&system=OnSite";
      }
      else if(this.isTracingStatistic) {
        url = "/DatabaseAuditLog/GetDatabaseByEntityName?entity=ContactTracingStatistic&system=OnSite";
      }
      else if(this.isSoftwareComponentType) {
        url = "/DatabaseAuditLog/GetDatabaseByEntityName?entity=SoftwareComponentType&system=OnSite";
      }
      else if(this.isSoftwareComponent) {
        url = "/DatabaseAuditLog/GetDatabaseAuditLogsByParentId?entity=SoftwareComponent&system=OnSite&parentId=" + this.installationId;
      }
      else if(this.isSoftwareComponentCommand) {
        url = "/DatabaseAuditLog/GetDatabaseAuditLogsByParentId?entity=SoftwareComponentCommand&system=OnSite&parentId=" + this.softwareComponentCommandParent;
      }
      else if(this.isSoftwareComponentLogin) {
        url = "/DatabaseAuditLog/GetDatabaseAuditLogsByParentId?entity=SoftwareComponentLogin&system=OnSite&parentId=" + this.softwareComponentParentId;
      }
      else if(this.isInstallationLogin) {
        url = "/DatabaseAuditLog/GetDatabaseAuditLogsByParentId?entity=InstallationLogin&system=OnSite&parentId=" + this.installationId;
      }
      else if(this.isInstallationEvents) {
        url = "/DatabaseAuditLog/GetDatabaseAuditLogsByParentId?entity=InstallationEvent&system=OnSite&parentId=" + this.installationId;
      }
      else if(this.isOnCallDuty) {
        url = "/DatabaseAuditLog/GetDatabaseByEntityName?entity=OnCallDuty&system=OnSite";
      }
      else if(this.isPeakLogin) {
        url = "/DatabaseAuditLog/GetDatabaseAuditLogsByParentIds?entity=InstallationLogin&system=OnSite&parentIds=" + this.componentIds.join(';');
      }
      else if(this.isSessionDelete) {
        url = "/DatabaseAuditLog/GetDatabaseAuditLogsByParentId?entity=Session&system=OnSite&parentId=" + this.installationId;
      }
      else if(this.isInstallationChangelog) {
        url = "/DatabaseAuditLog/GetDatabaseAuditLogsByParentId?entity=InstallationChangelog&system=OnSite&parentId=" + this.installationId;
      }
      else if(this.isInstallationLinks) {
        url = "/DatabaseAuditLog/GetDatabaseAuditLogsByParentId?entity=InstallationLink&system=OnSite&parentId=" + this.installationId;
      }
      else if(this.isBulkCommands) {
        url = "/DatabaseAuditLog/GetDatabaseByEntityName?entity=DeviceBulkCommand&system=OnSite";
      }
      else if(this.isQuickCommands) {
        url = "/DatabaseAuditLog/GetDatabaseByEntityName?entity=QuickSupportCommand&system=OnSite";
      }
      else if(this.isContactRoles) {
        url = "/DatabaseAuditLog/GetDatabaseByEntityName?entity=ContactRole&system=OnSite";
      }
      else if(this.isCustomers) {
        url = "/DatabaseAuditLog/GetDatabaseByEntityName?entity=Customer&system=OnSite";
      }
      else if(this.isContact) {
        url = "/DatabaseAuditLog/GetDatabaseByEntityName?entity=Contact&system=OnSite";
      }
      else if(this.isInstallationIp) {
        url = "/DatabaseAuditLog/GetDatabaseAuditLogsByParentId?entity=InstallationIp&system=OnSite&parentId=" + this.installationId;
      }

      this.axios.get(url)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.audits = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    rollbackObject () {
      let url = "";

      if(this.isThemes) {
        url = "/DatabaseAuditLog/RollbackThemes";
      }
      else if(this.isInstallationConfig) {
        url = "/DatabaseAuditLog/RollbackInstallationConfigs";
      }
      else if(this.templateIdForMessage) {
        url = "/DatabaseAuditLog/RollbackSmsMessages";
      }
      else if(this.isSmsTemplates) {
        url = "/DatabaseAuditLog/RollbackSmsTemplates";
      }
      else if(this.resortId) {
        url = "/DatabaseAuditLog/RollbackConverterConfigOrConnectionsParams";
      }
      else if(this.resortCrudId) {
        url = "/DatabaseAuditLog/RollbackResort";
      }
      else if(this.isCutConfigs) {
        url = "/DatabaseAuditLog/RollbackCutConfigs";
      }
      else if(this.isThemeElements) {
        url = "/DatabaseAuditLog/RollbackThemeElements";
      }
      else if(this.isIntroOutroElement) {
        url = "/DatabaseAuditLog/RollbackIntroOutros";
      }
      else if(this.introOutroId) {
        url = "/DatabaseAuditLog/RollbackIntroOutroScenes";
      }
      else if(this.isSounds) {
        url = "/DatabaseAuditLog/RollbackSoundElements";
      }
      else if(this.isFonts) {
        url = "/DatabaseAuditLog/RollbackFontElements";
      }
      else if(this.isCollections) {
        url = "/DatabaseAuditLog/RollbackImageCollections";
      }
      else if(this.collectionId) {
        url = "/DatabaseAuditLog/RollbackImageCollectionElements";
      }
      else if(this.isApplicationSetting) {
        url = "/DatabaseAuditLog/RollbackApplicationSettings";
      }
      else if(this.isAckMessages) {
        url = "/DatabaseAuditLog/RollbackAcknowledgeMessages";
      }
      else if(this.isSpots) {
        url = "/DatabaseAuditLog/RollbackSpot";
      }
      else if(this.isPhotopoint) {
        url = "/DatabaseAuditLog/RollbackPhotopoint";
      }
      else if(this.isLift) {
        url = "/DatabaseAuditLog/RollbackLift";
      }
      else if(this.isSharedResort) {
        url = "/DatabaseAuditLog/RollbackSharedResort";
      }
      else if(this.isRoutingRules) {
        url = "/DatabaseAuditLog/RollbackRoutingRule";
      }
      else if(this.isSoftwareComponentType) {
        url = "/DatabaseAuditLog/RollbackSoftwareComponentType";
      }
      else if(this.isSoftwareComponent) {
        url = "/DatabaseAuditLog/RollbackSoftwareComponent";
      }
      else if(this.isSoftwareComponentCommand) {
        url = "/DatabaseAuditLog/RollbackSoftwareComponentCommand";
      }
      else if(this.isSoftwareComponentLogin || this.isInstallationLogin || this.isPeakLogin) {
        url = "/DatabaseAuditLog/RollbackSoftwareComponentLogin";
      }
      else if(this.isQuestionTemplates) {
        url = "/DatabaseAuditLog/RollbackInstallationQuestionTemplates";
      }
      else if(this.templateIdForQuestion) {
        url = "/DatabaseAuditLog/RollbackInstallationQuestion";
      } 
      else if(this.isInstallationEvents) {
        url = "/DatabaseAuditLog/RollbackInstallationEvents";
      }
      else if(this.isInstallationLinks) {
        url = "/DatabaseAuditLog/RollbackInstallationLinks";
      }
      else if(this.isOnCallDuty) {
        url = "/DatabaseAuditLog/RollbackOnCallDuty";
      }
      else if(this.isContactRoles) {
        url = "/DatabaseAuditLog/RollbackContactRole";
      }
      else if(this.isCustomers) {
        url = "/DatabaseAuditLog/RollbackCustomer";
      }
      else if(this.isContact) {
        url = "/DatabaseAuditLog/RollbackContact";
      }
      else if(this.isInstallationIp) {
        url = "/DatabaseAuditLog/RollbackInstallationIp";
      }
      else if(this.isQuickCommands) {
        url = "/DatabaseAuditLog/RollbackQuickSupportCommands";
      }

      this.axios.post(url, this.selectedAuditLog)
        .then((response) => {
          if (response.status == 200) {
            this.$snotify.success(this.$t('rollbackSuccesful'));
            this.$refs.auditChanges.close();
            this.$emit("reload");
            this.reloadAuditLogs();
          }
        });
    }
  }
}
</script>
<style>
.applicationSettingsList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>